import React, { useEffect, useState } from "react";

import example1 from "./example/example1.png";
import example2 from "./example/example2.png";
import example3 from "./example/example3.jpg";
import example4 from "./example/example4.png";
import useMousePosition from "../../hooks/useTrackUsersMouse";
import useTrackUsersClick from "../../hooks/useTrackUsersClick";

import * as Styled from "./styles";

const Shape = ({ x, y }) => {
  return <Styled.Shape x={x} y={y} />;
};

const ClickShape = ({ x, y }) => {
  return <Styled.ClickShape x={x} y={y} />;
};

const Container = () => {
  const [shapesToRenderState, setShapesToRenderState] = useState([]);
  const [clickShapesToRenderState, setClickShapesToRenderState] = useState([]);
  const [colorOfShapesState, setColorOfShapes] = useState("red");
  const [colorOfClickShapesState, setColorOfClickShapes] = useState("green");
  const [isTestingState, setIsTestingState] = useState(true);
  const trackUsersMouseHook = useMousePosition();
  const trackUsersClickHook = useTrackUsersClick();

  const generateNewShape = () => {
    const newShape = React.createElement(Shape, {
      x: trackUsersMouseHook.x,
      y: trackUsersMouseHook.y,
    });
    setShapesToRenderState([...shapesToRenderState, newShape]);
  };

  const generateNewClickShape = () => {
    const newShape = React.createElement(ClickShape, {
      x: trackUsersClickHook.x,
      y: trackUsersClickHook.y,
    });
    setClickShapesToRenderState([...clickShapesToRenderState, newShape]);
  };

  useEffect(() => {
    generateNewShape();
  }, [trackUsersMouseHook.x, trackUsersMouseHook.y]);

  useEffect(() => {
    generateNewClickShape();
  }, [trackUsersClickHook.x, trackUsersClickHook.y]);

  return (
    <Styled.Wrapper>
      <Styled.GridComponent url={example1} />
      <Styled.GridComponent url={example2} />
      <Styled.GridComponent url={example3} />
      <Styled.GridComponent url={example4} />
      <Styled.Shape
        x={trackUsersMouseHook.x}
        y={trackUsersMouseHook.y}
        id="shape"
      />
      {shapesToRenderState.map((Comp) => Comp)}
      {clickShapesToRenderState.map((Comp) => Comp)}
      <Styled.Button
        onClick={() => {
          setShapesToRenderState([]);
          setClickShapesToRenderState([]);
        }}
      >
        CLEAR
      </Styled.Button>
    </Styled.Wrapper>
  );
};

export default Container;
