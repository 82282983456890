import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: lightgrey;
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;

export const GridComponent = styled.div`
  display: flex;
  flex-basis: calc(50%);
  justify-content: center;
  flex-direction: column;
  background-color: yellow;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
`;

export const Shape = styled.div`
  width: 5px;
  height: 5px;
  background-color: red;
  position: absolute;
  top: ${({ y }) => `${y}px`};
  left: ${({ x }) => `${x}px`};
`;

export const ClickShape = styled.div`
  width: 20px;
  height: 20px;
  background-color: green;
  position: absolute;
  top: ${({ y }) => `${y}px`};
  left: ${({ x }) => `${x}px`};
`;

export const Button = styled.button`
  font-size: 15px;
  padding: 5px 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  border: 1px solid black;
  background: white;
  z-index: 100;
`;
