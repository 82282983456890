import React from "react";

const useTrackUsersClick = () => {
  const [mousePosition, setMousePosition] = React.useState({
    x: null,
    y: null,
  });
  React.useEffect(() => {
    const updateClickPosition = (ev) => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };
    window.addEventListener("click", updateClickPosition);
    return () => {
      window.removeEventListener("click", updateClickPosition);
    };
  }, []);
  return mousePosition;
};
export default useTrackUsersClick;
